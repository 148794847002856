// @flow

import React from "react";
import {
    DefaultLayout,
    AdvancedSearchRequests,
    Layout,
    withPrivateRoute,
} from "@containers";
import { Helmet } from "react-helmet";

/**
 * Home Page
 */
const AdvancedSearchRequestsPage = (props: *) => (
    <Layout {...props}>
        <Helmet>
            <title>Geavanceerd zoeken: Aanvragen</title>
        </Helmet>
        <DefaultLayout title="Geavanceerd zoeken: Aanvragen">
            <AdvancedSearchRequests />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AdvancedSearchRequestsPage);
